import { Component, OnInit, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthState, UserCredentialModel } from '../../../../interfaces';

import * as AuthActions from '../../../../state/auth/auth.actions';
import * as AuthSelector from '../../../../state/auth/auth.reducer';
import { tap } from 'rxjs';
import { CommonOperations } from '../../../../helpers/common-operations';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'sm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  fg: FormGroup = {} as FormGroup;
  hide: boolean = true;
  public isSubmitted = false;
  cred: UserCredentialModel = {} as UserCredentialModel;

  private messageService = inject(MessageService); 
  private fb = inject(FormBuilder);

  constructor(
    private authStore: Store<AuthState>,
    private cOps: CommonOperations
  ) { }

  ngOnInit(): void {
    this.fg = this.fb.group({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      rememeberMe: new FormControl('')
    });

    this.authStore.pipe(
      select(AuthSelector.loginFailureSelector),
      tap((error) => {
        this.formAccessability(this.fg, true);
        if (error) {
          this.cOps.showHttpError(JSON.parse(error), this.fg);
        }
      })
    ).subscribe()
  }

  formAccessability(formGroup: FormGroup, isEnable: boolean) {
    isEnable ? formGroup.enable() : formGroup.disable();
    this.isSubmitted = !isEnable;
  }

  submit(formGroup: FormGroup) {
    if (formGroup.invalid) {
      this.messageService.add({ severity: 'warn', summary: 'Alert', detail: 'Required fields are empty' });
      return;
    }

    this.formAccessability(formGroup, false);

    this.cred = { ...this.cred, EmailAddress: formGroup.value.username?.trim(), Password: formGroup.value.password?.trim(), RememberMe: formGroup.value.rememeberMe }
    
    this.authStore.dispatch(AuthActions.loginRequest({ loginModel: this.cred }));
  }
}
