import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'shine-matrimony-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {

}
