
<div class="h-full w-full flex flex-column sm:flex-row">
        <div class="xs:w-full sm:w-6 secondary-bg flex flex-column justify-content-center align-items-center">
            <div class="fc la-c-c py-4">
                <a class="logo no-underline" href="javascript:void(0)"></a>
            </div>
        </div>
        <div class="xs:w-full sm:w-6 bg-white flex flex-column align-items-center justify-content-center px-5 py-7">
            <form #ngForm="ngForm" class="w-11 sm:w-9 md:w-7" [formGroup]="fg" autocomplete="off" (ngSubmit)="submit(fg)">
                    <div class="flex flex-column gap-5 align-items-stretch">

                        <div class="flex flex-column align-items-stretch">
                            <span class="p-float-label">
                                <input id="float-input" type="email" pInputText class="p-inputtext-lg w-100"
                                    formControlName="username" />
                                <label for="float-input">Email</label>
                            </span>
                            <ng-container
                                *ngIf="(fg.get('username')?.touched && ngForm.submitted) && 
                                (fg.get('username')?.hasError('required') || fg.get('username')?.hasError('email')
                                || fg.get('username')?.errors?.['serverError'])">
                                <span class="error-label text-xs" *ngIf="fg.get('username')?.hasError('required')">Email
                                    is required</span>
                                <span class="error-label text-xs" *ngIf="fg.get('username')?.hasError('email')">Invalid
                                    email address</span>
                                <!-- <span *ngIf="fg.get('username')?.errors?.['serverError']">{{fg.get('username')?.errors?.serverError}}</span> -->
                                <span class="error-label text-xs" *ngIf="fg.get('username')?.errors?.['serverError']">{{ fg.get('username')?.errors?.['serverError'] }}</span>

                            </ng-container>
                        </div>

                        <div class="flex flex-column align-items-stretch">
                            <span class="p-float-label p-input-icon-right">
                                <i class="pi" [ngClass]="hide ? 'pi-eye-slash' : 'pi-eye'" (click)="hide = !hide"></i>
                                <input id="float-input" pInputText class="p-inputtext-lg w-100"
                                    [type]="hide ? 'password' : 'text'" formControlName="password" />
                                <label for="float-input">Password</label>
                            </span>
                            <ng-container
                                *ngIf="(fg.get('password')?.touched && ngForm.submitted) 
                                && ( fg.get('password')?.hasError('required') 
                                || fg.get('password')?.hasError('pattern')
                                || fg.get('password')?.errors?.['serverError'] )">
                                <span class="error-label text-xs" *ngIf="fg.get('password')?.hasError('required')">Password
                                    is required</span>
                                <span class="error-label text-xs" *ngIf="fg.get('password')?.hasError('invalid')">Invalid
                                    password</span>
                                <span class="error-label text-xs" *ngIf="fg.get('password')?.errors?.['serverError']">{{ fg.get('password')?.errors?.['serverError'] }}</span>
                            </ng-container>
                        </div>

                        <div class="stretch-control">
                            <p-button type="submit" aria-label="btn" severity="primary" styleClass="p-button-lg">Submit</p-button>
                        </div>

                    </div>
            </form>
        </div>
</div>
